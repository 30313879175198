export const isEmail = (email = '') => {
  return email.indexOf('@') !== -1;
};

export const getPhoneInServerFormat = (phone = '') => {
  const normNumber = phone.trim().replace(/[^0-9]+/g, '');
  let result = normNumber;
  if (normNumber.startsWith('7')) {
    result = `+${ normNumber }`;
    return result;
  }
  if (normNumber.startsWith('8')) {
    result = `+7${ normNumber.slice(1) }`;
  }
  if (normNumber.length === 10) {
    result = `+7${ normNumber }`;
  }
  return result;
};
