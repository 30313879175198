import { actions } from '../store/store';

export const listenMessages = () => {
  window.addEventListener('message', e => {
    const { data: { source, payload } } = e;
    if (source !== 'avtoappProject') {
      return;
    }

    if (payload.projectId) {
      actions.setProjectId(payload.projectId);
    }
  });
};
