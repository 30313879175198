import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { FormikContext } from 'formik';
import Box from '@material-ui/core/Box';

const SubmitButton = (props) => {
  const {
    children,
    ...rest
  } = props;

  const { isSubmitting } = useContext(FormikContext);

  return (
    <Box mt={ 1 }>
      <Button
        variant='contained'
        color='secondary'
        type='submit'
        size='large'
        disabled={ isSubmitting }
        { ...rest }
      >
        {children}
      </Button>
    </Box>
  );
};

SubmitButton.propTypes = {
  children: PropTypes.any,
};

export { SubmitButton };
