import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { inject, observer } from 'mobx-react';

// import { Routes, BrowserRouter } from 'react-router-dom';
// import { Route } from 'react-router';
import { actions, store } from '../store/store';
import { SignInPage } from './SignInPage';
import { PhoneVerifyPage } from './PhoneVerifyPage';
import { ClientUpdatePage } from './ClientUpdatePage';
// import * as paths from '../misc/paths';
import imgSrcVK from '../assets/img/vk_primary.png';
import imgSrcNP from '../assets/img/nik_parts.png';
import imgSrcVKMobile from '../assets/img/vk_primary_mobile.png';
import imgSrcNPMobile from '../assets/img/nik_parts_mobile.png';

const AuthWidget = inject('store')(observer(({ store }) => {

  const {
    stylePassport,
    projectId,
    formState,
    isMobile,
    isDevMode,
  } = store;


  useEffect(() => {
    actions.gotoHomeIfNoLoginPresent();
  });

  const routerComponent = () => {
    switch (formState) {
      case 1:
        return (<SignInPage />);
      case 2:
        return (<PhoneVerifyPage />);
      case 3:
        return (<ClientUpdatePage />);
    }
    return (<SignInPage />);
    /* return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<SignInPage />} />
          <Route path={paths.PHONE_VERIFY} element={<PhoneVerifyPage />} />
          <Route path={paths.CLIENT_UPDATE} element={<ClientUpdatePage />} />
        </Routes>
      </BrowserRouter>
    ) */
  };


  // console.log('projectId=',projectId);

  const selectDevContainer = () => {
    if (isMobile) {return 'vezemKolesa-dev-container-mobile' };
    return 'vezemKolesa-dev-container';
  }
  
  return (
    <>
      <div className={`${stylePassport} ${ isDevMode ? selectDevContainer() : null}`}> { /* TODO - только на DEV отражать контейнер */}
        {stylePassport === 'vezemKolesa' && !isMobile &&
          <div className='main-container'>
            <div className='image-container'>
             {projectId === 'd202b3fa-7be6-4d9b-adbe-1c4d7938e942'  ?  <img src={imgSrcVK} alt='Avtoapp'></img> : <img src={imgSrcNP} alt='Avtoapp'></img> }
            </div>
            <div className='content-container'>
              {routerComponent()}
            </div>
          </div>
        }

        {stylePassport === 'vezemKolesa' && isMobile &&
          <div className='main-container-mobile'>
            <div className='image-container'>
             {projectId === 'd202b3fa-7be6-4d9b-adbe-1c4d7938e942'  ?  <img src={imgSrcVKMobile} alt='Avtoapp'></img> : <img src={imgSrcNPMobile} alt='Avtoapp'></img> }
            </div>
            <div className='content-container'>
              {routerComponent()}
            </div>
          </div>
        }


        {stylePassport === 'classic' &&
          <Grid
            container
            direction='row'
            justifyContent='center'
          >
            <Grid item xs={12} md={10}>
              <Box p={3} style={{ minHeight: '100vh' }}>
                {routerComponent()}
              </Box>
            </Grid>
          </Grid>
        }

      </div>
    </>
  );
}));

export { AuthWidget };
