import React, { useEffect, useState } from 'react';
import 'fontsource-roboto';
import "@fontsource/manrope";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'mobx-react';

import { ThemeProvider } from '@material-ui/core/styles';
import { store, actions } from './store/store';
import { AuthWidget } from './containers/AuthWidget';
import { listenMessages } from './messaging/messaging';
import { sendMessage } from './messaging/windowMessenger';
import { vezemKolesaTheme } from './styles/vezemKolesaTheme';
import { classicTheme } from './styles/classicTheme';
import './styles/main.scss';

import { useMedia } from 'react-use';

function App() {
  const getThemeByProjectId = (projectId) => {
    if (projectId === 'd202b3fa-7be6-4d9b-adbe-1c4d7938e942') {
      return vezemKolesaTheme;
    }
    return classicTheme;
  };

  useEffect(() => {
    listenMessages();
    sendMessage({ isLoaded: true });
    // actions.getGenders();
  });

  const isMobileMedia = useMedia('(max-width: 768px)');
  actions.setIsMobile(isMobileMedia);
  


  globalThis.setProjectId = (projectId) => {  actions.setProjectId(projectId); };
  globalThis.setIsMobile = (isMobile) => {  actions.setIsMobile(isMobile); };
  globalThis.setIsDevMode = (idDevMode) => {  actions.setIsDevMode(idDevMode); };

  return (
    <Provider store={store}>
      <ThemeProvider theme={getThemeByProjectId(store?.projectId)}>
          <CssBaseline />
          <Container maxWidth='sm' fixed>
            <AuthWidget />
          </Container>
      </ThemeProvider>
    </Provider>
  );
}

export { App };
