import { request } from './request-api';

// const GENDERS_PATH = '/v1/client_auth/genders';
const AUTH_PATH = '/v3/client/auth';
const CLIENT_PATH = '/v1/client';

export const controller = {

  /* getGendersList: () => {
    return request('get', GENDERS_PATH);
  },*/

  checkPhoneNumber: (data) => {
    return request('post', `${ AUTH_PATH }/captcha`, {
      captchaToken: data.captchaToken,
      fcmToken: 'empty',
      phoneNumber: data.phoneNumber,
    });
  },

  verifyPhoneUnified: (data) => {
    return request('post', `${ AUTH_PATH }/confirm/captcha`, data);
  },

  updateClientUnified: (data) => {
    // Упрощённый метод - только дополняет клиента (Имя и Фамилия):
    return request('put', `${ CLIENT_PATH }/supplement`, { firstName: data.firstName, lastName: data.lastName });
    // Полный метод:
    // return request('put', `${ CLIENT_PATH }`, data);
  },

  getClientData: () => {
    return request('get', `${ CLIENT_PATH }/profile`);
  },

};
