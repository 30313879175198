import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import Box from '@material-ui/core/Box';


const CheckboxField = React.forwardRef((props, ref) => {
  const { name, label } = props;
  const [field, , helper] = useField(name);

  useEffect(() => {
    if (!field.value) {
      helper.setValue('');
    }
  }, [field.value]);

  return (
    <Box mt={ 5 }>
    <Field
      { ...field }
      component={ CheckboxWithLabel }
      inputRef={ ref }
      type='checkbox'
      name={ name }
      Label={ { label } }
      { ...props }
    />
    </Box>
  );
});

CheckboxField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export { CheckboxField };
