import axios from 'axios';
import merge from 'lodash/merge';
import { store } from '../store/store';

// axios.defaults.withCredentials = true;
const instance = axios.create({
  baseURL: process.env.API_URL,
  validateStatus: status => {
    return status >= 200 && status <= 503;
  },
});

export const request = async (method, url, data, customHeaders, customConfig) => {
  const headers = {
    'Content-Type': 'application/json',
    ...customHeaders,
  };

  if (store.token) {
    headers.Authorization = `Bearer ${ store.token }`;
  } else if (store.authData.token) {
    // не обязательная ветка кода. Думакю, можно удалить
    headers.Authorization = `Bearer ${ store.authData.token }`;
  }

  const config = merge({
    method,
    url,
    data,
    headers,
    // withCredentials: true,
  }, customConfig);
  let response;
  try {
    response = await instance.request(config);
  } catch (e) {
    response = e;
  }

  /* if (!response.data) {
    // eslint-disable-next-line no-console
    console.error(response);
    throw response;
  } */

  const { data: body } = response;
  if (body.errorCode || body.error) {
    throw body;
  }
  return body;
};
