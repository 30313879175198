import React from 'react';
// import ReactDOM from 'react-dom/client';
import { createRoot } from 'react-dom/client';

import { App } from './App';

// const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(<App />);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab='root' />);

// ReactDOM.render(<App />, document.getElementById('kolesa-root'));