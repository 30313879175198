import * as yup from 'yup';
export const registrationValidationSchema = yup.object().shape({
    firstName: yup.string()
        .matches(/^[а-яё]*$/i, 'Поддерживает только кириллица')
        .required('Введите имя'),
    /* lastName: yup.string()
      .matches(/^[а-яё]*$/i, 'Поддерживает только кириллица')
      .required('Введите фамилию'),
     genderRefValueId: yup.number()
      .nullable()
      .required('Выберите пол'),
       email: yup.string()
      .email('Введите корректный email')
      .required('Введите email'),
    dateOfBirth: yup.string()
      .nullable()
      .required('Укажите дату рождения'),
    password: yup.string()
      .min(8, '8 символов минимум')
      .max(32, '32 символа максимум')
      .matches(/(?=.*?[a-zа-яё](?=.*?[0-9])){8,32}/i, 'Как минимум 1 буква и 1 цифра')
      .required('Введите пароль'),
    passwordRepeat: yup.string()
      .required('Повторите пароль')
      .oneOf([yup.ref('password'), null], 'Пароли должны совпадать!'), */
});
