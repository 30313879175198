import React, { useEffect, useContext } from 'react';
import { Form, Formik } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { inject, observer } from 'mobx-react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
// import { useNavigate } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import { ErrorAlert } from './ErrorAlert';


function WidgetForm(props, ref) {
  
  const {
    children,
    onSubmit,
    title,
    store,
    onGoBack,
    hideGoBack,
    ...rest
  } = props;

  // const navigate = useNavigate();

  const { error, path, stylePassport } = store;

  const errorToShow = (s)=> {
    if (s==='Wrong login or password') {
      return 'Неправильный код';
    }
    if (s==='Password expired') {
      return 'Неправильный код';
    }
    return s; 
  }
  

  const handleSubmit = async (values, form) => {
    await onSubmit(values, form);
  };

  const handleGoBack = async () => {
    onGoBack?.();
  };

  const handleClose = async () => {
    if (globalThis.onLoginWidgetClose) { globalThis.onLoginWidgetClose(); } 
  };
  
  return (
    <Formik
      onSubmit={ handleSubmit }
      innerRef={ ref }
      initialValues={ {} }
      { ...rest }
    >
      {context => (
        <>
          {title && stylePassport==='classic' && (
            <Box mb={ 2 } display='flex'>
              {onGoBack && (
                <Box mr={ 1 }>
                  <IconButton onClick={ handleGoBack }><ArrowBackIosIcon /></IconButton>
                </Box>
              )}
              <Typography component='h1' variant='h4'>{title}</Typography>
            </Box>
          )}
          {title && stylePassport==='vezemKolesa' && (
              <div className='title-row'>
              <div className='title'>
              { !hideGoBack && <IconButton onClick={ handleGoBack }><ArrowBackIosIcon /></IconButton> }
              <Typography component='h1' variant='h4'>{title}</Typography>
              </div>
              <IconButton onClick={ handleClose }><CloseIcon /></IconButton>
              </div>
          )}
          <Form>
            <ErrorAlert errors={ errorToShow } />
            <FormControl>
              {typeof children === 'function' ? children(context) : children}
            </FormControl>
          </Form>
        </>
      )}
    </Formik>
  );
}

export default inject('store')(observer(React.forwardRef(WidgetForm)));
