import React, { useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// import MuiLink from '@material-ui/core/Link';
// import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';

import { toJS } from 'mobx';

// import { useHistory } from 'react-router';
import WidgetForm from '../components/WidgetForm';
import { InputField } from '../components/InputField';
// import { DateOfBirthInput } from '../components/DateOfBirthInput';
// import RadioSelect from '../components/RadioSelect';
import { SubmitButton } from '../components/SubmitButton';
import { actions } from '../store/store';
import { registrationValidationSchema } from '../validation/clientValidation';

const ClientUpdatePage = inject('store')(observer(({ store }) => {
  const MARGIN_BETWEEN_FIELDS = 2;

  const {
    login,
    clientData,
    isMobile,
    // genders,
  } = store;

  const formRef = useRef(null);

  const handleSubmit = async (values) => {
    await actions.updateClient(values);
  };

  useEffect(() => {
    if (!login.trim()) {
      // actions.setPath('/');
      actions.setFormState(1);
    }
  });

  return (
    <WidgetForm
      title='Обновление данных'
      initialValues={toJS(clientData)}
      onSubmit={handleSubmit}
      validationSchema={registrationValidationSchema}
      ref={formRef}
    >
      {!isMobile &&
        <>
          <Box mt={1}>
            <Typography component='p'>
              <Box component='span' color='#6A7287' fontSize={12}>Телефон</Box>
            </Typography>
            { login ?? '' }
            { /* <MuiLink component={ Link } to='/'>
          { login }
        </MuiLink> */ }
          </Box>
          <Box mt={MARGIN_BETWEEN_FIELDS}>
            <Divider />
          </Box>
          <Box mt={MARGIN_BETWEEN_FIELDS}>
            <InputField
              name='firstName'
              label='Имя'
              required
            />
          </Box>
          <Box mt={MARGIN_BETWEEN_FIELDS}>
            <InputField
              name='lastName'
              label='Фамилия'
            // required
            />
          </Box>
          <SubmitButton>Сохранить</SubmitButton>
        </>
      }


      {isMobile &&
        <div className='mobile-content-flex'>
          <div>

            <Box mt={1}>
              <Typography component='p'>
                <Box component='span' color='#6A7287' fontSize={12}>Телефон</Box>
              </Typography>
              { login ?? '' }
              { /* <MuiLink component={ Link } to='/'>
          { login }
        </MuiLink> */ }
            </Box>
            <Box mt={MARGIN_BETWEEN_FIELDS}>
              <Divider />
            </Box>
            <Box mt={MARGIN_BETWEEN_FIELDS}>
              <InputField
                name='firstName'
                label='Имя'
                required
              />
            </Box>
            <Box mt={MARGIN_BETWEEN_FIELDS}>
              <InputField
                name='lastName'
                label='Фамилия'
              // required
              />
            </Box>
          </div>
          <div className='bottom-content'>
            <SubmitButton>Сохранить</SubmitButton>
          </div>
        </div>
      }


    </WidgetForm >
  );
}));

export { ClientUpdatePage };


{ /*
  <Box mt={ 4 }>
    <RadioSelect
      name='genderRefValueId'
      label='Пол'
      data={ genders.map(g => ({ title: g.gender, value: g.refValueId })) }
      // required
    />
  </Box>
   <Box mt={ MARGIN_BETWEEN_FIELDS }>
    <DateOfBirthInput
      name='dateOfBirth'
      label='Дата рождения'
      required
/>
  </Box>
  <Box mt={ MARGIN_BETWEEN_FIELDS }>
    <InputField
      name='email'
      label='Email'
      type='email'
      helperText='Email потребуется для восстановления пароля'
      autoComplete='off'
      required
    />
  </Box>
  <Box mt={ MARGIN_BETWEEN_FIELDS }>
    <InputField
      name='password'
      label='Пароль'
      type='password'
      required
    />
  </Box>
  <Box mt={ MARGIN_BETWEEN_FIELDS }>
    <InputField
      name='passwordRepeat'
      label='Повтор пароля'
      type='password'
      required
    />
</Box> */ }

