import * as yup from 'yup';
import { getPhoneInServerFormat } from '../misc/utils';
export const phoneCodeVerifyValidationSchema = yup.object().shape({
    code: yup.string()
        .min(4, '4 символа')
        .max(4, '4 символа')
        .required('Введите код'),
});
export const phoneValidationSchema = yup.object().shape({
    phoneNumber: yup.string()
        .nullable()
        .required('Введите номер телефона')
        .test('phone-length', 'Введите номер телефона', (value) => {
        return getPhoneInServerFormat(value).length === 12;
    }),
});
export const passwordSignInValidationSchema = yup.object().shape({
    login: yup.string()
        .required('Введите телефон'),
    password: yup.string()
        .required('Введите пароль'),
});
export const setPasswordValidationSchema = yup.object().shape({
    password: yup.string()
        .min(8, '8 символов минимум')
        .max(32, '32 символа максимум')
        .matches(/(?=.*?[a-zа-яё](?=.*?[0-9])){8,32}/i, 'Как минимум 1 буква и 1 цифра')
        .required('Введите пароль'),
    passwordRepeat: yup.string()
        .required('Повторите пароль')
        .oneOf([yup.ref('password'), null], 'Пароли должны совпадать!'),
});
export const emailValidationSchema = yup.object().shape({
    email: yup.string()
        .email('Укажите корректный email')
        .required('Введите email')
        .nullable(),
});
