import React, { useContext, useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { FormikContext } from 'formik';
import IMask from 'imask';
import { InputField } from '../components/InputField';
import { actions } from '../store/store';

const LoginInputField = inject('store')(observer(props => {
  const { store, name = 'login', ...rest } = props;
  const { login } = store;
  const { setFieldValue, handleChange } = useContext(FormikContext);

  const inputRef = useRef();

  const applyMask = (target) => {
    IMask(target, {
      mask: '+{7} (000) 000-00-00',
    });
  };

  const handleInputChange = (e) => {
    handleChange(e);
    applyMask(e.target);
    actions.setLogin(e.target.value);
  };

  useEffect(() => {
    setFieldValue(name, login);
    applyMask(inputRef.current);
  }, []);

  return (
    <InputField
      name={ name }
      label='Мобильный телефон'
      onChange={ handleInputChange }
      ref={ inputRef }
      value={ login }
      autoComplete='off'
      { ...rest }
    />
  );
}));

export { LoginInputField };
