export const LOGIN_OR_PASSWORD_INCORRECT = 'LOGIN_OR_PASSWORD_INCORRECT';
export const PHONE_CODE_EXPIRED = 'PHONE_CODE_EXPIRED';
export const PHONE_CODE_INVALID = 'PHONE_CODE_INVALID';
export const EMAIL_CODE_EXPIRED = 'EMAIL_CODE_EXPIRED';
export const EMAIL_CODE_INVALID = 'EMAIL_CODE_INVALID';

export const resolveError = (error) => {
  switch (error) {
    case LOGIN_OR_PASSWORD_INCORRECT:
      return 'Неверный логин или пароль';
    case PHONE_CODE_EXPIRED:
    case EMAIL_CODE_EXPIRED:
      return 'Код истек';
    case PHONE_CODE_INVALID:
    case EMAIL_CODE_INVALID:
      return 'Неверный код';
    default:
      return null;
  }
};
