import createTheme from '@material-ui/core/styles/createTheme';

export const classicTheme = createTheme({
  palette: {
    primary: {
      main: '#457AB2',
      contrastText: '#fff',
    },
    secondary: {
      main: '#5CB85C',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h4: {
      fontFamily: 'OpenSansCondensed',
      fontSize: 32,
      color: '#000',
    },
    button: {
      color: '#fff',
      fontStyle: 'italic',
      textTransform: 'none',
    },
  },
  overrides: {
    MuiButton: {
      label: {
        fontSize: 18,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 12,
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #EEE',
        },
        '&:hover:not($disabled):not(.Mui-error):after': {
          borderBottom: '1px solid #457AB2',
        },
      },
    },
  },
});
