import React, { useEffect, useState, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import Box from '@material-ui/core/Box';

import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import WidgetForm from '../components/WidgetForm';
import { InputField } from '../components/InputField';
import { actions } from '../store/store';
import { LoginInputField } from './LoginInputField';
import { SubmitButton } from '../components/SubmitButton';
import { CooldownButton } from './CooldownButton';
import { phoneCodeVerifyValidationSchema } from '../validation/loginValidation';
import { getPhoneInServerFormat } from '../misc/utils';

const PhoneVerifyPage = inject('store')(observer(({ store }) => {
  const {
    login,
    projectId,
  } = store;

  const [token, setToken] = useState('');
  const [visible, setVisible] = useState(false);

  const { isMobile } = store;

  const handleChallengeHidden = useCallback(() => setVisible(false), []);

  const handleSubmit = async (values) => {
    await actions.verifyPhone(getPhoneInServerFormat(login), values.code);
  };

  const handleResendCode = async () => {
    try {
      if (token) {
        await actions.checkPhoneNumber(getPhoneInServerFormat(login), token);
        setToken(null);
        setVisible(false);
      }
    } catch (e) {
      return e;
    }
    return null;
  };

  const handleGoBack = async () => {
    // actions.setPath('/');
    actions.setFormState(1);
  };

  useEffect(() => {
    if (token) { handleResendCode(); }
    // eslint-disable-next-line
  }, [token]);

  return (
    <WidgetForm
      // title='Войти в личный кабинет'
      title='Введите код'
      initialValues={{
        projectId,
        phoneNumber: login,
        code: '',
      }}
      validationSchema={phoneCodeVerifyValidationSchema}
      onSubmit={handleSubmit}
      onGoBack={handleGoBack}
    >

      <>
        {!isMobile &&
          <>
            <LoginInputField name='phoneNumber' disabled />
            <Box mt={2}>
              <InputField name='code' label='СМС-код' autoFocus />
              <CooldownButton
                onClick={() => { setVisible(true); }}
                cooldown={60}
                cooldownText='Повторная отправка возможна через'
                buttonText='Отправить повторно'
              />
            </Box>
            <SubmitButton disabled={visible}>Войти</SubmitButton>
          </>
        }

        {isMobile &&
          <div className='mobile-content-flex'>
            <div>
              <LoginInputField name='phoneNumber' disabled />
              <InputField name='code' label='СМС-код' autoFocus />
              </div>
            <div className='bottom-content'>
              <Box mt={2}>
                <CooldownButton
                  onClick={() => { setVisible(true); }}
                  cooldown={60}
                  cooldownText='Повторная отправка возможна через'
                  buttonText='Отправить повторно'
                />
              </Box>
              <SubmitButton disabled={visible}>Войти</SubmitButton>
            </div>
          </div>
        }

        {visible && (
          <InvisibleSmartCaptcha
            sitekey={process.env.YANDEX_CAPTCHA_KEY}
            onSuccess={setToken}
            onChallengeHidden={handleChallengeHidden}
            visible={visible}
          />
        )}
      </>
    </WidgetForm>
  );
}));

export { PhoneVerifyPage };
