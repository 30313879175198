export const sendMessage = (payload, callback, target = '*') => {
  if (!window.opener) {
    return;
  }
  window.opener.postMessage({
    source: 'avtoapp',
    payload,
  }, target);

  if (typeof callback === 'function') {
    callback();
  }
};
