import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'formik';
import { TextField } from 'formik-material-ui';

const InputField = React.forwardRef((props, ref) => {
  const { name } = props;
  const [field, , helper] = useField(name);

  useEffect(() => {
    if (!field.value) {
      helper.setValue('');
    }
  }, [field.value]);

  return (
    <Field
      { ...field }
      component={ TextField }
      inputRef={ ref }
      value={ field.value || '' }
      { ...props }
    />
  );
});

InputField.propTypes = {
  name: PropTypes.string,
};

export { InputField };
