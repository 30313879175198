import React, { useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';

const ErrorAlert = props => {
  const { errors } = props;

  const [err, setErr] = useState(Array.isArray(errors) ? errors : [errors]);

  useEffect(() => {
    if (!Array.isArray(errors)) {
      setErr([errors]);
    }
  }, [errors]);

  return !isEmpty(errors) ? (
    <>
      {err?.length > 0 && (
        <Box mt={ 1 } mb={ 1 }>
          {err.map(error => (
            <Alert severity='error' key={ error }>{error}</Alert>
          ))}
        </Box>
      )}
    </>
  ) : null;
};

export { ErrorAlert };
