import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link, Typography } from '@material-ui/core';

const SmallText = styled(Typography)`
  font-size: 12px;
  color: #6A7287;
`;

const SmallLink = styled(Link)`
  font-size: 12px;
`;

function CooldownButton(props) {
  const {
    cooldown: defaultCooldown,
    onClick,
    buttonText,
    cooldownText,
  } = props;

  const [cooldown, setCooldown] = useState(60);

  const handleClick = async (e) => {
    e.preventDefault();
    setCooldown(defaultCooldown);
    if (typeof onClick === 'function') {
      await onClick();
    }
  };

  useEffect(() => {
    if (cooldown > 0) {
      const timerId = setTimeout(() => {
        setCooldown(cooldown - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
    return () => {};
  }, [cooldown]);

  return cooldown === 0 ? (
    <SmallLink href='#' onClick={ handleClick }>{buttonText}</SmallLink>
  ) : (
    <SmallText component='p'>{cooldownText} {cooldown}</SmallText>
  );
}

CooldownButton.propTypes = {
  cooldown: PropTypes.number,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  cooldownText: PropTypes.string,
};

export { CooldownButton };
