var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { action, observable, reaction, runInAction } from 'mobx';
import { controller } from '../api/controller';
import { getPhoneInServerFormat } from '../misc/utils';
import { resolveError } from '../misc/errors';
import { sendMessage } from '../messaging/windowMessenger';
export const storeInitialValues = {
    login: localStorage.getItem('login') || '+7 (',
    projectId: 'd202b3fa-7be6-4d9b-adbe-1c4d7938e942',
    isMobile: false,
    isDevMode: false,
    authData: {
        token: null,
        clientId: null,
    },
    clientId: '',
    token: null,
    clientData: {},
    checkPhoneNumberResponse: {
        showForm: null,
    },
    error: null,
    // genders: [],
    path: '/',
    stylePassport: 'vezemKolesa',
    formState: 1,
};
export const store = observable(storeInitialValues);
export const actions = {
    setProjectId: action((projectId) => {
        store.projectId = projectId;
        if (projectId === 'd202b3fa-7be6-4d9b-adbe-1c4d7938e942' || projectId === '571ed133-6581-46a1-ae90-8c4fb3b6a6d2') {
            store.stylePassport = 'vezemKolesa';
        }
        else {
            store.stylePassport = 'classic';
        }
    }),
    setIsMobile: action((isMobile) => {
        store.isMobile = isMobile;
    }),
    setIsDevMode: action((isDevMode) => {
        store.isDevMode = isDevMode;
    }),
    setLogin: action((login) => {
        store.login = login;
    }),
    handleError: action((err) => {
        if (typeof err === 'object') {
            store.error = err.message;
        }
        else {
            store.error = err;
        }
    }),
    resetError: action(() => {
        store.error = null;
    }),
    /* setPath: action((path) => {
      store.path = path;
    }),*/
    setStylePassport: action((style) => {
        store.stylePassport = style;
    }),
    verifyPhone: (phoneNumber, code) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield controller.verifyPhoneUnified({
                phoneNumber: getPhoneInServerFormat(phoneNumber),
                password: code,
                projectId: store.projectId,
            });
            const error = resolveError(response.status);
            if (error) {
                actions.handleError(error);
            }
            if (!error) {
                runInAction(() => {
                    store.clientId = response.clientId;
                    store.token = response.token;
                });
            }
            // store.clientId = '2e6bb0eb-3e3f-4072-8337-9810c3e67b2c';
            // eslint-disable-next-line max-len
            // store.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJodHRwczovL2FwaS5kZXYuYXZ0b2FwcC5vbmxpbmUiLCJhdWQiOiJjbGllbnQiLCJzdWIiOiIrNzkyNjc3NjU4OTciLCJleHAiOjE3NTI1MjM5OTh9.Oi0VL1PGMJeeuGVuEzosfPIu7yFTWRmhUyNgs9_HdvFEkP0a5R9OsBKw_f-KagAyS69SJ-uTYq3XBtm1Wn_SHA';
        }
        catch (err) {
            actions.handleError(err);
        }
    }),
    checkPhoneNumber: (phoneNumber, captchaToken) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield controller.checkPhoneNumber({ phoneNumber, captchaToken });
            action(() => {
                store.checkPhoneNumberResponse = response;
            })();
            // store.path = paths.PHONE_VERIFY;
            store.formState = 2; // 2 - phone verify
        }
        catch (err) {
            actions.handleError(err);
        }
    }),
    getClientData: () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield controller.getClientData();
            action(() => {
                store.clientData = response;
            })();
        }
        catch (err) {
            actions.handleError(err);
        }
    }),
    /* getGenders: async () => {
      try {
        const genders = await controller.getGendersList();
        action(() => {
          store.genders = genders;
        })();
      } catch (err) {
        actions.handleError(err);
      }
    }, */
    updateClient: (data) => __awaiter(void 0, void 0, void 0, function* () {
        const rest = __rest(data, []);
        try {
            const response = yield controller.updateClientUnified(Object.assign({ projectId: store.projectId, phoneNumber: getPhoneInServerFormat(store.login) }, rest));
            // sendMessage(response, window.close);
            const error = resolveError(response.status);
            if (!error) {
                action(() => {
                    store.authData = {
                        clientId: store.clientId,
                        token: store.token,
                    };
                })();
            }
        }
        catch (err) {
            actions.handleError(err);
        }
    }),
    gotoHomeIfNoLoginPresent: () => {
        if (process.env.NODE_ENV === 'development') {
            return;
        }
        // if (store.login === storeInitialValues.login && window.location.pathname !== '/') {
        if (store.login === storeInitialValues.login && store.formState !== 1) {
            store.formState = 1;
        }
    },
    setFormState: action((newState) => {
        store.formState = newState;
    }),
    /* resetStore: action(() => {
      store.clientId = storeInitialValues.clientId;
      store.authData = storeInitialValues.authData;
      // store.clientId = storeInitialValues.clientData;
      store.checkPhoneNumberResponse = storeInitialValues.checkPhoneNumberResponse;
      store.error = storeInitialValues.error;
    }), */
};
reaction(() => store.login, () => {
    if (getPhoneInServerFormat(store.login).length > 11) {
        localStorage.setItem('login', store.login);
    }
});
/* reaction(() => store.checkPhoneNumberResponse.showForm, () => {
  const showForm = store.checkPhoneNumberResponse.showForm;
  if (showForm) {
    gotoRouteByShowFormField(showForm);
  }
}); */
// при получении токена получим данные клиента
reaction(() => store.token, () => {
    if (store.token) {
        action(() => {
            actions.getClientData();
        })();
    }
});
reaction(() => store.clientData, () => {
    if (store.token && store.clientId && store.clientData.firstName) {
        action(() => {
            store.authData = {
                clientId: store.clientId,
                token: store.token,
            };
        })();
    }
    if (store.token && store.clientId && !store.clientData.firstName) {
        // store.path = paths.CLIENT_UPDATE;
        store.formState = 2;
    }
});
reaction(() => store.authData, () => {
    if (store.authData.token && store.authData.clientId) {
        if (globalThis.onSuccessLogin) {
            globalThis.onSuccessLogin(store.authData.token, store.authData.clientId);
        }
        sendMessage(Object.assign({}, store.authData), window.close);
        action(() => {
            store.authData = { clientId: null, token: null };
        })();
    }
});
